



























import { Component, Prop, Vue } from 'vue-property-decorator'

import { ITableOptions, PaginationMeta } from '@/store/types'

interface ITablePagination {
  page: number,
  itemsPerPage: number,
  pageStart: number,
  pageStop: number,
  pageCount: number,
  itemsLength: number,
}

@Component
export default class TableFooter extends Vue {
  /**
   * NOTE:
   * В зависимости от места использования
   * если в таблице Vuetify то table
   * если с кастомными табличками
   * то надо paginator (передаётся объект пагинатора с бэка)
   */
  @Prop({ default: 'table',
    validator (value: string): boolean {
      return !!value.match(/(table|paginator)/)
    },
  })
  private mod!: string

  @Prop({ default: false, type: Boolean })
  private sticky!: true

  @Prop({ required: true })
  private pagination!: ITablePagination | PaginationMeta

  @Prop({ default: () => ({}) })
  private filter!: ITableOptions

  private get pageCount (): number {
    return this.mod === 'table'
      ? (this.pagination as ITablePagination).pageCount
      : (this.pagination as PaginationMeta).lastPage
  }

  private get pageInfo () {
    const itemsLength: number = this.mod === 'table'
      ? (this.pagination as ITablePagination).itemsLength
      : (this.pagination as PaginationMeta).total

    const itemsPerPage: number = this.mod === 'table'
      ? (this.pagination as ITablePagination).itemsPerPage
      : (this.pagination as PaginationMeta).perPage

    let pageStart: number = this.mod === 'table'
      ? (this.pagination as ITablePagination).pageStart
      : (this.pagination as PaginationMeta).from

    let pageStop: number = this.mod === 'table'
      ? (this.pagination as ITablePagination).pageStop
      : (this.pagination as PaginationMeta).to

    if (itemsLength && itemsPerPage) {
      pageStart = this.mod === 'table' ? pageStart + 1 : pageStart
      pageStop = itemsLength < pageStop || pageStop < 0 ? itemsLength : pageStop

      return `${pageStart}-${pageStop} из ${itemsLength}`
    }

    return ''
  }

  private get page (): number {
    return this.mod === 'table'
      ? this.filter.page
      : (this.pagination as PaginationMeta).currentPage
  }

  private set page (value) {
    this.$emit('update:filter', {
      ...this.filter,
      page: value,
    })
  }

  private get perPage (): number {
    return this.mod === 'table'
      ? this.filter.itemsPerPage
      : (this.pagination as PaginationMeta).perPage
  }

  private set perPage (value: number) {
    if (this.mod === 'table') {
      this.$emit('update:filter', {
        ...this.filter,
        itemsPerPage: value,
        page: 1,
      })
    } else {
      this.$emit('update:filter', {
        ...this.filter,
        perPage: value,
        page: 1,
      })
    }
  }
}
